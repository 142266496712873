/* General Reset */
:root {
	--base-font-size: 2.7rem;
	--base-line-height: 1.5;
}
@media (max-width: 767px) {
	:root {
		--base-font-size: 1.6rem;
	}
}

html {
	height: 100%;
	font-size: 10px;
}
@media (max-width: 1919px) {
	html {
		font-size: 8px;
	}
}
@media (max-width: 1439px) {
	html {
		font-size: 7px;
	}
}
@media (max-width: 767px) {
	html {
		font-size: 2.57vw;
	}
}
@media (max-width: 389px) {
	html {
		font-size: 8px;
	}
}

body {
	margin: 0;
	font-size: var(--base-font-size);
	line-height: var(--base-line-height);
	font-family: "Poppins", Arial, sans-serif;
	font-weight: 400;
	letter-spacing: 0;
	color: #000;
	background: #fff;
	min-width: 320px;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

* {
	box-sizing: border-box;
}

/* General settings */
main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

img {
	max-width: 100%;
	height: auto;
}
img.lazyload {
	opacity: 0;
	transition: opacity 0.3s;
}
img.lazyloaded {
	opacity: 1;
}

/* section-404 */
.section-404 {
	flex-grow: 1;
	background: #493c70;
	color: #fff;
	padding: 10rem 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: hidden;
}
@media (max-width: 767px) {
	.section-404 {
		padding: 5rem 0;
	}
}
.section-404 .container-fluid {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	padding: 0 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
@media (max-width: 767px) {
	.section-404 .container-fluid {
		padding: 0 3rem;
	}
}
.section-404 .img {
	margin: 0 auto 4.7rem;
	max-width: 92rem;
	width: 100%;
}
@media (max-width: 767px) {
	.section-404 .img {
		max-width: 30rem;
		margin-bottom: 3.4rem;
	}
}
.section-404 .img img {
	display: block;
	width: 100%;
}
.section-404 h1, .section-404 .h1 {
	margin: 0 0 8.2rem;
	text-transform: uppercase;
	font: 5.3rem/1.2 "Poppins", Arial, sans-serif;
	position: relative;
}
@media (max-width: 767px) {
	.section-404 h1, .section-404 .h1 {
		font-size: 3.6rem;
		margin-bottom: 3rem;
	}
}
.section-404 h1::after, .section-404 h1::before, .section-404 .h1::after, .section-404 .h1::before {
	content: attr(data-text);
	position: absolute;
	color: #836cdf;
	left: 0;
	top: 0;
	z-index: 3;
	pointer-events: none;
	transform: translate(0.8rem, 0.2rem);
}
@media (max-width: 767px) {
	.section-404 h1::after, .section-404 h1::before, .section-404 .h1::after, .section-404 .h1::before {
		transform: translate(0.4rem, 0.2rem);
	}
}
.section-404 h1::after, .section-404 .h1::after {
	z-index: 5;
	color: rgba(28, 204, 151, 0.9);
	transform: translate(0.2rem, -0.2rem);
}
.section-404 .box {
	max-width: 89.8rem;
	margin: 0 auto;
	padding: 1.8rem 8rem 3rem;
	font-weight: 500;
	border: 2px solid;
	border-radius: 3rem;
	width: 100%;
	border-radius: 2rem;
}
@media (max-width: 767px) {
	.section-404 .box {
		padding: 1.5rem 2rem;
	}
}
.section-404 .box p {
	margin: 0;
}