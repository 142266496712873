/* General Reset */
:root {
	--base-font-size: #{$font-size-base};
	--base-line-height: #{$line-height-base};

	@include media('<tablet') {
		--base-font-size: 1.6rem;
	}
	// dev3
	// dev4
	// dev5
}

html {
	height: 100%;
	font-size: 10px;

	@include media('<xxl') {
		font-size: 8px;
	}

	@include media('<xl') {
		font-size: 7px;
	}

	@include media('<tablet') {
		//font-size: 10px;
		font-size: 2.57vw;
	}

	@include media('<phone') {
		font-size: 8px;
	}
}

body {
	margin: 0;
	font-size: var(--base-font-size);
	line-height: var(--base-line-height);
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	letter-spacing: $letter-spacing-base;
	color: $base-color;
	background: $body-bg;
	min-width: 320px;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

* {
	box-sizing: border-box;
}

/* General settings */
main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

img {
	max-width: 100%;
	height: auto;

	&.lazyload {
		opacity: 0;
		transition: opacity 0.3s;
	}

	&.lazyloaded {
		opacity: 1;
	}
}

// dev2
// dev3
// dev4
// dev5
