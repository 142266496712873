// vars
$social-font-path: '../fonts/';
$fonts: '../fonts/';
$images: '../images/';
$fa-font-path: '../fonts/fontawesome/';

$breakpoints: (
	phone: 390px,
	tablet: 768px,
	desktop: 992px,
	minscroll: 1025px,
	xl: 1440px,
	xxl: 1920px,
);

// fonts
$Poppins: "Poppins", Arial, sans-serif;
$font-family-base: $Poppins;
$font-size-base: 2.7rem;
$line-height-base: 1.5;
$font-weight-base: 400;
$letter-spacing-base: 0;

// global colors
$white: #fff;
$black: #000;

$gray-100: #fdfdfd !default;
$gray-200: #f7f7f7 !default;
$gray-300: #c6c6c7 !default;
$gray-400: #d6d6d6 !default;
$gray-500: #6a89a6 !default;
$gray-600: #555555 !default;
$gray-700: #333333 !default;
$gray-800: #2c2d2d !default;
$gray-900: #050505 !default;

$indigo: #1a405b !default;
$purple: #493c70;
$purple-2: #836cdf;
$pink: #ff9190 !default;
$red: #e43c2f !default;
$orange: #e87722 !default;
$yellow: #f7a600 !default;
$green: #06a00e !default;
$cyan: #1ccc97;
$teal: #64b6c3 !default;
$blue: #002d4e !default;
$beige: #fff3ea !default;
$brown: #4f321e !default;

// colors
$body-bg: $white;
$base-color: $black;
$heading-color: inherit;

// transition vars
$transition: all 0.3s ease;

// dev2
// dev3
// dev4
// dev5
