/* section-404 */
.section-404 {
	flex-grow: 1;
	background: $purple;
	color: $white;
	padding: 10rem 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: hidden;

	@include media('<tablet') {
		padding: 5rem 0;
	}

	.container-fluid {
		margin-top: auto;
		margin-bottom: auto;
		width: 100%;
		padding: 0 4rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media('<tablet') {
			padding: 0 3rem;
		}
	}

	.img {
		margin: 0 auto 4.7rem;
		max-width: 92rem;
		width: 100%;

		@include media('<tablet') {
			max-width: 30rem;
			margin-bottom: 3.4rem;
		}

		img {
			display: block;
			width: 100%;
		}
	}

	h1, .h1 {
		margin: 0 0 8.2rem;
		text-transform: uppercase;
		font: 5.3rem/1.2 $font-family-base;
		position: relative;

		@include media('<tablet') {
			font-size: 3.6rem;
			margin-bottom: 3rem;
		}

		&::after,
		&::before {
			content: attr(data-text);
			position: absolute;
			color: $purple-2;
			left: 0;
			top: 0;
			z-index: 3;
			pointer-events: none;
			transform: translate(.8rem, .2rem);

			@include media('<tablet') {
				transform: translate(.4rem, .2rem);
			}
		}

		&::after {
			z-index: 5;
			color: rgba($cyan, 0.9);
			transform: translate(.2rem, -.2rem);
		}
	}

	.box {
		max-width: 89.8rem;
		margin: 0 auto;
		padding: 1.8rem 8rem 3rem;
		font-weight: 500;
		border: 2px solid;
		border-radius: 3rem;
		width: 100%;
		border-radius: 2rem;

		@include media('<tablet') {
			padding: 1.5rem 2rem;
		}

		p {
			margin: 0;
		}
	}
}